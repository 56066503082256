.tiptap {
  /* Global styles */
  padding-top: 10px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  min-height: 50vh;
  max-height: 55vh;
  overflow-y: auto;
  :first-child {
    margin-top: 0;
  }
  /* Text color styles */
  h1, h2, h3, h4, h5, h6, p, li, a, span, div {
    color: #212121;
  }
  
  /* Ensure text color is applied to all text elements */
  * {
    color: #212121;
  }

  /* List styles */
  // ul,
  // ol {
  //   padding: 0 1rem;
  //   margin: 1.25rem 1rem 1.25rem 0.4rem;

  //   li p {
  //     margin-top: 0.25em;
  //     margin-bottom: 0.25em;
  //   }
  // }
  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6,
  // // p {
  // //   font-family: "Ubuntu", sans-serif;
  // //   font-size: 14px;
  // //   font-weight: 400;
  // //   color: "#212121";
  // // }
  // a {
  //   text-decoration: underline;
  //   color: "#212121";
  // }
  // /* Heading styles */
  // h1,
  // h2,
  // h3,
  // h4,
  // h5,
  // h6 {
  //   line-height: 1.1;
  //   margin-top: 2.5rem;
  //   text-wrap: pretty;
  // }

  // h1,
  // h2 {
  //   margin-top: 3.5rem;
  //   margin-bottom: 1.5rem;
  // }

  // h1 {
  //   font-size: 1.4rem;
  // }

  // h2 {
  //   font-size: 1.2rem;
  // }

  // h3 {
  //   font-size: 1.1rem;
  // }

  // h4,
  // h5,
  // h6 {
  //   font-size: 1rem;
  // }

  // /* Code and preformatted text styles */
  // code {
  //   background-color: var(--purple-light);
  //   border-radius: 0.4rem;
  //   color: var(--black);
  //   font-size: 0.85rem;
  //   padding: 0.25em 0.3em;
  // }

  // pre {
  //   background: var(--black);
  //   border-radius: 0.5rem;
  //   color: var(--white);
  //   font-family: "JetBrainsMono", monospace;
  //   margin: 1.5rem 0;
  //   padding: 0.75rem 1rem;

  //   code {
  //     background: none;
  //     color: inherit;
  //     font-size: 0.8rem;
  //     padding: 0;
  //   }
  // }

  // blockquote {
  //   border-left: 3px solid var(--gray-3);
  //   margin: 1.5rem 0;
  //   padding-left: 1rem;
  // }

  // hr {
  //   border: none;
  //   border-top: 1px solid var(--gray-2);
  //   margin: 2rem 0;
  // }
}
// .tiptap p.is-editor-empty:first-child::before {
//   // font-size: 14px;
//   // line-height: 20px;
//   color: #a6a6a6;
//   content: attr(data-placeholder);
//   float: left;
//   height: 0;
//   pointer-events: none;
// }
