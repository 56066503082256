@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  /* @font-face {
    font-family: "Ubuntu";
    src: url("/public/fonts/Ubuntu-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("/public/fonts/Ubuntu-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("/public/fonts/Ubuntu-Italic.ttf") format("truetype");
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("/public/fonts/Ubuntu-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("/public/fonts/Ubuntu-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
  }

  @font-face {
    font-family: "Ubuntu";
    src: url("/public/fonts/Ubuntu-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
  } */
}
:root {
  --toastify-toast-min-height: 52px;
}
.ProseMirror:focus {
  outline: none;
}

.tiptap {
  line-height: 20px;
  font-size: 14px;
  font-family: "Ubuntu", sans-serif;
  padding: 8px;
}

.tiptap hr {
  margin: 16px 0;
}

.tiptap ul {
  list-style-type: disc;
  padding-left: 20px;
}

.tiptap ol {
  list-style-type: decimal;
  padding-left: 20px;
}

/* For nested lists */
.tiptap ul ul,
.tiptap ol ul {
  list-style-type: circle;
}

.tiptap a {
  color: #3b82f6;
  text-decoration: underline;
}

.Toastify__close-button {
  padding-top: 10px !important;
}
.Toastify__close-button > svg {
  fill: rgba(77, 77, 77, 1) !important;
}

#gu-outlook .button,
#gu-outlook button {
  margin: 0px;
  text-transform: none;
  min-width: 0px;
  border-width: 0;
  padding: 0;
  color: unset;
}
#gu-outlook input,
#gu-outlook .select,
#gu-outlook select,
#gu-outlook textarea {
  border: initial;
  padding: 0;
  color: nofill;
}

#gu-outlook .tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  height: 0;
  pointer-events: none;
}

#gu-outlook .email-content ol {
  list-style-type: decimal;
  padding-left: 16px;
  padding-bottom: 4px;
}

#gu-outlook .email-content ul {
  list-style-type: disc;
  padding-left: 16px;
}

#gu-outlook .email-content {
  word-break: break-word;
}

#gu-outlook .email-content p {
  margin-bottom: 1em;
}

#gu-outlook .email-content a {
  color: #3b82f6;
  text-decoration: none;
}

#gu-outlook .email-content a:hover {
  text-decoration: underline;
}
#gu-outlook .email-content blockquote {
  border-left: 2px solid #e5e7eb;
  padding-left: 1rem;
  margin: 1rem 0;
  color: #6b7280;
}
#gu-outlook .email-content .email-separator {
  border-top: 1px solid #e5e7eb;
  margin: 1rem 0;
}
#gu-outlook .email-content table {
  border-collapse: collapse;
  width: 100%;
}

#gu-outlook .email-content td {
  padding: 0.5rem;
}

#gu-outlook .email-content .pooh img {
  width: unset !important;
  height: auto;
}
